<template>
  <div>
    <div class="ltitle">
      <span>Liych Object Manager System</span>
    </div>
    <el-card class="box-card" shadow="hover">
      <el-form :model="userForm" status-icon :rules="rules" ref="userForm" label-width="100px" class="demo-ruleForm" style="padding-top: 30px;padding-bottom: 30px">
        <el-form-item label="用户名：" prop="userName">
          <el-input type="text" v-model="userForm.userName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="密  码：" prop="passWord">
          <el-input type="password" v-model="userForm.passWord" autocomplete="off" @keyup.enter.native="submitForm('userForm')"></el-input>
        </el-form-item>
<!--        <el-form-item label="验证码：" prop="age">-->
<!--          <el-input v-model.number="ruleForm.age"></el-input>-->
<!--        </el-form-item>-->
        <el-form-item style="margin: auto">
          <el-button type="primary" @click="submitForm('userForm')" @keydown.enter="submitForm('userForm')">登录</el-button>
          <el-button @click="resetForm('userForm')">重置</el-button>
          <el-link type="info" :underline="false" style="padding-left: 10px; padding-right: 10px" href="forget">忘记密码</el-link>
          <el-link type="info" :underline="false" href="register">注册</el-link>
        </el-form-item>
      </el-form>
    </el-card>
    <div style="text-align: center;margin-top: 20px; font-size: 13px">
      <el-link :underline="false" disabled style="padding-right: 10px">©2022 塞北明珠</el-link>
      <el-link href="https://beian.miit.gov.cn/" :underline="false" type="info">冀ICP备19009747号-1 </el-link>
      <el-link :underline="false" disabled style="padding-left: 10px;padding-right: 10px"> Version:V2.0.1 技术支持：LIYCH技术团队 详情：</el-link>
      <el-link href="https://www.liych.cn/" :underline="false" type="info">Liych Style</el-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "login",
  data() {
    var validateUser = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入用户名'));
      } else {
        callback();
      }
    };
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        callback();
      }
    };
    return {
      userForm: {
        userName: '',
        passWord: ''
      },
      currentRow: null,
      rules: {
        userName: [
          { validator: validateUser, trigger: 'blur' }
        ],
        passWord: [
          { validator: validatePass, trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    submitForm(userForm) {
      this.$refs[userForm].validate((valid) =>{
        if (valid) {
          this.$http({
            method: 'post',
            url: '/api/sso/login',
            data: this.userForm
          }).then((res) =>{
            if (res.data.status) {
              window.localStorage.setItem('Token', res.data.data)
              window.localStorage.setItem('UserName', this.userForm.userName)
              this.$router.push("/admin")
            } else {
              this.$message({
                type: 'error',
                message: res.data.msg
              })
            }
          })
        }
      })
    },
    resetForm(userForm) {
      this.$refs[userForm].resetFields();
    },
  }
}
</script>

<style scoped>

.text {
  font-size: 14px;
}

.item {
  padding: 18px 0;
}

.box-card {
  width: 480px;
  margin: auto;

  padding-top: 10px;
}
.ltitle {
  text-align: center;
  padding-top: 8%;
  padding-bottom: 2%;
  font-size: 50px;
}
</style>